import React, { Component } from 'react';
import { ReactComponent as CloseIcon} from '../../../icons/close.svg';
import { ReactComponent as DownwardIcon} from '../../../icons/arrow_downward.svg';
import { ReactComponent as UpwardIcon} from '../../../icons/arrow_upward.svg';

//TODO:: repalce it
const sortLabels = {
  CreateDate: 'По дате добавления',
  GenreTracksString: 'По жанру',
  Rating: 'По рейтингу',
  Duration: 'По длительности',
  Artist: 'По артисту',
  Publication: 'По публикации'
};

class TracksFilter extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      currentOpenedFilter: ''
    };
  }

  toggleOpenedFilter(filter, e) {
    const currentOpenedFilter = this.state.currentOpenedFilter === filter ? '' : filter;
    this.setState({ currentOpenedFilter });
    this.props.toggleOpenedTracksFilter(currentOpenedFilter);
  }

  sortTracksBy(sortBy, e) {
    e.preventDefault();
    const {sortDirection, tracksSortDirection, peaks} = this.props;
    this.props.changeSortBy(sortBy);
    this.props.toggleFetchTracksLoader(true, true);
    this.props.fetchTracks(peaks, sortBy, tracksSortDirection);
  }

  changeTracksSortDirection(e) {
    e.preventDefault();
    const {sortTracksBy, tracksSortDirection, peaks} = this.props;
    let direction = tracksSortDirection === 'asc' ? 'desc' : 'asc';
    this.props.changeTracksSortDirection(direction);
    this.props.toggleFetchTracksLoader(true, true);
    this.props.fetchTracks(peaks, sortTracksBy, direction);
  }

  removeSort(e) {
    e.preventDefault();
    const {sortTracksBy, tracksSortDirection, peaks} = this.props;
    this.props.removeTracksSort();
    this.props.toggleFetchTracksLoader(true, true);
    this.props.fetchTracks(peaks, '', tracksSortDirection);
    return false;
  }

  render() {

    let {tracksFilter, sortTracksBy, tracksSortDirection} = this.props;
    let filterLabelClass = tracksSortDirection === 'asc' ? 'filter-label_down' : 'filter-label_up';

    return (
      <div className="filter-container" id="filters">
          <div className="filter-container__top-table">
            <div className="filter-container__top-table-col">
              <b className="filter-container__name">CОРТИРОВКА:</b>
            </div>
            <div className="filter-container__top-table-col">
              <a className="filter-item filter-container__item" onClick={this.sortTracksBy.bind(this, 'CreateDate')}>
                <span className="filter-item__text">{sortLabels.CreateDate}</span>
              </a>
              <a className="filter-item filter-container__item" onClick={this.sortTracksBy.bind(this, 'Rating')}>
                <span className="filter-item__text">{sortLabels.Rating}</span>
              </a>
              <a className="filter-item filter-container__item" onClick={this.sortTracksBy.bind(this, 'Duration')}>
                <span className="filter-item__text">{sortLabels.Duration}</span>
              </a>
              <a className="filter-item filter-container__item" onClick={this.sortTracksBy.bind(this, 'Artist')}>
                <span className="filter-item__text">{sortLabels.Artist}</span>
              </a>
              <a className="filter-item filter-container__item" onClick={this.sortTracksBy.bind(this, 'Publication')}>
                <span className="filter-item__text">{sortLabels.Publication}</span>
              </a>
            </div>
          </div>
          <div className="filter-container__labels">
              { sortTracksBy !== '' &&
                <a className={"filter-label filter-label_sort filter-container__label " + filterLabelClass}>
                    <span className="filter-label__text" onClick={this.changeTracksSortDirection.bind(this)}>{sortLabels[sortTracksBy]}</span>
                    <DownwardIcon className="filter-label__icon filter-label__icon_arrow_down svg-icon" onClick={this.changeTracksSortDirection.bind(this)}/>
                    <UpwardIcon className="filter-label__icon filter-label__icon_arrow_up svg-icon" onClick={this.changeTracksSortDirection.bind(this)}/>
                    <CloseIcon className="filter-label__icon filter-label__icon_close svg-icon" onClick={this.removeSort.bind(this)}/>
                </a>
              }
          </div>
          <div className="filter-container__accordions">
              <div className="filter-container__accordion" id="sortingFilter" style={{maxHeight: (tracksFilter === 'sortingFilter' ? 1000 : 0)}}>
                  <a className="filter-container__accordion-close" onClick={this.toggleOpenedFilter.bind(this, '')}>
                    <CloseIcon className="svg-icon"/>
                  </a>
              </div>
          </div>
      </div>
    )
  }
}

export default TracksFilter;
