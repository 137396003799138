import React, { Component } from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';

import {ReactComponent as ArrowLeftIcon} from '../../../icons/keyboard_arrow_left.svg';
import {ReactComponent as ArrowRightIcon} from '../../../icons/keyboard_arrow_right.svg';
import {renderField} from "../../renderField";
import StatisticHelpers from "../../../helpers/Statistic";

const WEEKDAYS_SHORT = {
  ru: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']
};
const MONTHS = {
  ru: [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь'
  ]
};

const FIRST_DAY_OF_WEEK = {
  ru: 1
};

function Navbar({
                  month,
                  nextMonth,
                  previousMonth,
                  onPreviousClick,
                  onNextClick
                }) {
  return (
    <div className="calendar-controls calendar-container__controls calendar-container__range">
      <button className="calendar-controls__arrow" onClick={() => onPreviousClick()}><ArrowLeftIcon className="svg-icon" /></button>
      <span className="calendar-controls__text">{MONTHS['ru'][month.getMonth()]}, {month.getFullYear()} год</span>
      <button className="calendar-controls__arrow" onClick={() => onNextClick()}><ArrowRightIcon className="svg-icon" /></button>
    </div>
  );
}

export default class StatisticCalendar extends Component {

  constructor(props, context) {
    super(props, context);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleDayMouseEnter = this.handleDayMouseEnter.bind(this);
    this.handleResetClick = this.handleResetClick.bind(this);
    this.state = this.getInitialState();
  }

  getInitialState() {
    const firstDay = this.props.start;
    const lastDay = this.props.end;
    return {
      dateFromText: this.renderDate(firstDay),
      dateToText: this.renderDate(lastDay),
      from: firstDay,
      to: lastDay,
      enteredTo: lastDay, // Keep track of the last day for mouseEnter.
    };
  }

  getResetState() {
    return {
      dateFromText: null,
      dateToText: null,
      from: null,
      to: null,
      enteredTo: null, // Keep track of the last day for mouseEnter.
    };
  }

  isSelectingFirstDay(from, to, day) {
    const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || isRangeSelected;
  }

  handleDayClick(day) {
    const { from, to } = this.state;
    if (from && to && day >= from && day <= to) {
      this.handleResetClick();
      return;
    }
    if (this.isSelectingFirstDay(from, to, day)) {
      this.setState({
        dateFromText: this.renderDate(day),
        dateToText: null,
        from: day,
        to: null,
        enteredTo: null,
      });
    } else {
      this.setState({
        dateToText: this.renderDate(day),
        to: day,
        enteredTo: day,
      }, () => this.props.changeDateRange(from, day));
    }
  }

  handleDayMouseEnter(day) {
    const {from, to} = this.state;
    if (!this.isSelectingFirstDay(from, to, day)) {
      this.setState({
        enteredTo: day,
      });
    }
  }

  handleResetClick() {
    this.setState(
      this.getResetState(),
      () => this.props.changeDateRange(this.state.from, this.state.to));
  }

  renderDate(date) {
    return StatisticHelpers.RenderDate(date);
  }

  render() {
    const { from, to, enteredTo, dateFromText, dateToText } = this.state;
    const modifiers = { start: from, end: enteredTo };
    const disabledDays = { before: this.state.from, after: this.state.to };
    const selectedDays = [from, { from, to: enteredTo }]
    return (
      <span>
        {
          <span className="statistic-calendar-wrap">
            <div className = "calendar-container" >
              <DayPicker onDayClick={this.handleDayClick}
                         onDayMouseEnter={this.handleDayMouseEnter}
                         selectedDays={selectedDays}
                         numberOfMonths={1}
                         disabledDays={disabledDays}
                         modifiers={modifiers}
                         showOutsideDays
                         locale="ru"
                         months={MONTHS['ru']}
                         weekdaysShort={WEEKDAYS_SHORT['ru']}
                         firstDayOfWeek={FIRST_DAY_OF_WEEK['ru']}
                         navbarElement={<Navbar />}
                         classNames={{
                           day: 'calendar__item',
                           selected: 'calendar__item calendar__item_bg_blue calendar__item_color_white',
                           disabled: 'calendar__item calendar__item_color_grey',
                           navBar: 'calendar-controls calendar-container__controls',
                           navButtonPrev: 'calendar-controls__arrow',
                           navButtonNext: 'calendar-controls__arrow',
                           caption: '',
                           month: '',
                           outside: 'DayPicker-Day--outside',
                           today: 'calendar__item calendar__item_bg_grey calendar__item_color_white',
                           weekday: 'calendar__item',
                           weekdays: '',
                           body: 'DayPicker-Body',
                           week: '',
                           weekNumber: '',
                           weekdaysRow: 'calendar__content calendar__content_head'
                         }}
              />
            </div>
            <div className="calendar-controls calendar-container__result">
              { renderField({
                  type: 'text',
                  name: 'from',
                  disabled: true,
                  input: {value: dateFromText != null ? `С: ${dateFromText}` : 'Выберите день начала'}})
              }
              { from &&
                renderField({
                  type: 'text',
                  name: 'from',
                  disabled: true,
                  input: {value: dateToText != null ? `По: ${dateToText}` : 'Выберите день завершения'}} )
              }
            </div>
          </span>
        }
      </span>
    )
  }
}
