import React from 'react';
import {Creatable} from 'react-select';
import {onlyUnique} from '../utils/collections';

export const renderField = (data) => {
  const { input, label, type, placeholder, required, initialError, value, disabled, meta } = data;
  const isError = meta && (initialError || meta.touched) && meta.error;
  return (
    <div className="inputbox">
      {label &&
      <div className="inputbox__left">
        <p className="text-small">
          {label}
          {
            required &&
            <span>*</span>
          }
        </p>
      </div>
      }
      <div className="inputbox__right">
        <input {...input} className={'input ' + (isError ? 'inputbox__error' : '')} type={type}
               placeholder={placeholder} disabled={disabled} defaultValue={value}/>
      </div>
    </div>
  );
};

export const renderSelectField = (props) => {
  const { input, label, required, options, isStringArray, disabled } = props;
  let defaultValue = '';
  if (isStringArray) {
    defaultValue = input.value
      .filter(onlyUnique)
      .map(v => {
        return {
          value: v, label: v
        };
      });
  }
  const customStyles = {
    control: (provided) => ({
      ...provided,
      background: '#fff',
      borderColor: '#ececec',
      borderWidth: '1',
      minHeight: '32px',
      ':hover': {borderColor: '#00e1c3'},
      ':focus': {borderColor: '#00e1c3'},
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: '5px'
    }),
  };

  const handleChange = (val) => {
    input.onChange(val);
  };

  return (<div className="inputbox">
    {label &&
    <div className="inputbox__left">
      <p className="text-small">
        {label}
        {
          required &&
          <span>*</span>
        }
      </p>
    </div>
    }
    <div className="inputbox__right">
      <Creatable isMulti creatable onChange={handleChange} className="basic-multi-select" classNamePrefix="select"
                 options={options || []} disabled={disabled} defaultValue={defaultValue} styles={customStyles} />
    </div>
  </div>);
};
