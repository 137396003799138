import React from 'react';
import { reduxForm, Field } from 'redux-form';
import {renderField, renderSelectField} from '../../renderField';
import connect from "react-redux/es/connect/connect";

import {updateTrack, updateTrackFailure, updateTrackSuccess} from "../../../actions/catalog";

const validate = (values) => {
  const errors = {};
  let hasErrors = false;
  if (!values.artist || values.artist.trim() === '') {
    errors.artist = 'Enter artist';
    hasErrors = true;
  }
  if (!values.title || values.title.trim() === '') {
    errors.title = 'Enter title';
    hasErrors = true;
  }
  if (!values.isrc || values.isrc.trim() === '' || values.isrc.length < 12) {
    errors.isrc = 'Enter ISRC code';
    hasErrors = true;
  }
  if ((values.year && values.year.trim() !== '') && values.year.length !== 4) {
    errors.year = 'Enter year';
    hasErrors = true;
  }
  return hasErrors && errors;
};

const validateAndSaveInfo = (values, dispatch) => {
  const {trackId, album, artist, title, isrc, year} = values;
  const request = {
    trackId,
    album,
    artist,
    title,
    isrc,
    year,
    genres: values.genreTracks.map(v => (v.label && v.label) || v)
  };
  return dispatch(updateTrack(request)).then(_ => {
    return dispatch(updateTrackSuccess(request));
  }).catch((error) => {
    return dispatch(updateTrackFailure(error.response.body));
  });
};

const validateAndSendTicket = (values) => {
  const {trackId, album, artist, title, isrc, year} = values;
  const request = {
    trackId,
    album,
    artist,
    title,
    isrc,
    year,
    genres: values.genreTracks.map(v => (v.label && v.label) || v)
  };

  const {owner} = this.props;
  const header = `Запрос информации на изменения трека ${owner.name}: ${trackId}`;
  const msg = `Owner: ${owner.name};<br/>TrackId: ${trackId}<br/>Album: ${album}<br/>Artist: ${artist}<br/>Title: ${title}<br/>ISRC: ${isrc}<br/>Year: ${year}<br/>Genres: ${request.genres}`;
  const ticketType = 12;

  if (msg && msg !== '' && header !== '') {
    this.props.createTicket(header, msg, ticketType);
    setTimeout(this.props.clearTicket, 5000);
  }
};

class EditTrackModuleForm extends React.Component {
  constructor(props, context) {
    super(props, context);

    // this.state = {
    // };
  }

  componentDidMount() {
    this.props.fetchGenres();
  }

  componentWillReceiveProps(nextProps) {
    if (Object.keys(nextProps.editingTrack).length > 0 && (!this.props.editingTrack || this.props.editingTrack.trackId !== nextProps.editingTrack.trackId) ) {
      this.props.initialize(nextProps.editingTrack);
    }
  }

  render() {
    const {handleSubmit, invalid, submitting, genres, editingTrack} = this.props;

    const genresOptions = genres.map((g) => {
      return {
        value: g.name,
        label: g.name
      };
    });

    const disabledSubmit = invalid || submitting;
    return (
        <form className="edit-track__form" method="post" onSubmit={ handleSubmit(validateAndSaveInfo) }>

          <Field
            name="title"
            type="text"
            key={"EDITING_TRACK_TITLE" + editingTrack.trackId}
            component={ renderField }
            initialError={true}
            label="Title"
            placeholder="Title" />

          <Field
            name="artist"
            type="text"
            key={"EDITING_TRACK_ARTIST" + editingTrack.trackId}
            component={ renderField }
            initialError={true}
            label="Artist"
            placeholder="Artist" />

          <Field
            name="album"
            type="text"
            key={"EDITING_TRACK_ALBUM" + editingTrack.trackId}
            component={ renderField }
            initialError={true}
            label="Album"
            placeholder="Альбом" />

          <Field
            name="isrc"
            type="text"
            key={"EDITING_TRACK_ISRC" + editingTrack.trackId}
            component={ renderField }
            initialError={true}
            label="Isrc (i.e. US12Z3456789, minimum length 12)"
            placeholder="Isrc" />

          <Field
            name="year"
            type="text"
            key={"EDITING_TRACK_YEAR" + editingTrack.trackId}
            component={ renderField }
            initialError={true}
            label="Year"
            placeholder="Year" />

          <Field
            name="genreTracks"
            type="text"
            key={"EDITING_TRACK_GENRES" + editingTrack.trackId}
            options={genresOptions}
            isStringArray={true}
            component={ renderSelectField }
            label="Genres" />

            <div className="inputbox">
              <button className={'button ' + (disabledSubmit ? 'button_color_white' : 'button_color_blue') + ' button_size_big button_weight_normal account__button'}
                      type="submit"
                      disabled={disabledSubmit}
              >СОХРАНИТЬ</button>
            </div>
        </form>
    );
  }
}

EditTrackModuleForm = reduxForm({
  form: 'EditTrackModuleForm',
  validate,
  enableReinitialize: true,
})(EditTrackModuleForm);

export default connect(
  state => ({
    initialValues: state.catalog.editingTrack,
  })
)(EditTrackModuleForm)



