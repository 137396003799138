import React from 'react';


const renderInputField = ({ input, type, placeholder }) => (
  <span>
    <input {...input} className="input" placeholder={placeholder} />
  </span>
);

export default renderInputField;
