import React from 'react';
import { Route } from 'react-router-dom';
import Aside from './components/Layout/Aside';
import MainHeader from './components/Main/Header';
import LeftSidebar from './components/Main/LeftSidebar.js';

export const PublicRoute = ({components: Components, ...rest}) => {
  return (
    <Route {...rest} render={matchProps => (
        <div className="layout">
        <Aside>
          <LeftSidebar {...matchProps} />
        </Aside>
        <div className="layout__center">
          <MainHeader {...matchProps} />
          <main className="layout__content" role="main">
            <Components.main {...matchProps} />
          </main>
        </div>
        <Aside klass="layout__right-side" innerKlass="layout__right-block">
        </Aside>
      </div>
    )} />
  )
};
