import React, {Component} from 'react';

export default class UploadFileModule extends Component {

  constructor(props, context) {
    super(props, context);
    this.createTicket = this.createTicket.bind(this);
    this.state = {};
  }

  createTicket(e) {
    e.preventDefault();
    const {owner} = this.props;
    const header = `Запрос информации на загрузку ${owner.name}`;
    const msg = `Owner: ${owner.name};`;
    const ticketType = 11;
    if (msg && msg !== '' && header !== '') {
      this.props.createTicket(header, msg, ticketType);
      setTimeout(this.props.clearTicket, 5000);
    }
  }

  render() {
    const {ticket} = this.props;
    return (
      <div className="calendar-controls calendar-container__result calendar-container__result_value">
        {!ticket &&
        <button className="button button_size_big button_color_white button_weight_normal account__button"
                type="submit"
                onClick={this.createTicket}>
          ЗАГРУЗИТЬ ФАЙЛЫ
        </button>
        }
        {ticket &&
        <button className="button button_size_big button_color_light-grey button_weight_normal account__button"
                type="submit"
                disabled={true}>
          ЗАПРОС ОТПРАВЛЕН
        </button>
        }
      </div>
    )
  }
}
