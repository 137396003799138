import {api} from '../index';
import fetch from 'cross-fetch';
import UrlHelpers from "../helpers/Url";
import UserHelpers from "../helpers/User";
import NProgress from "nprogress";
import {createTicketFailure, createTicketSuccess} from "./users";
import moment from "moment";

const Mubicon = require('@mubicon/api');

export const TOGGLE_LOADER  = 'TOGGLE_LOADER';
export const TOGGLE_FETCH_TRACKS_LOADER = 'TOGGLE_FETCH_TRACKS_LOADER';

export const GET_TRACKS_LIST = 'GET_TRACKS_LIST';
export const GET_TRACKS_LIST_SUCCESS = 'GET_TRACKS_LIST_SUCCESS';
export const GET_TRACKS_LIST_FAILURE = 'GET_TRACKS_LIST_FAILURE';
export const FETCH_PEAKS_SUCCESS = 'FETCH_PEAKS_SUCCESS';
export const FETCH_PEAKS_FAILURE = 'FETCH_PEAKS_FAILURE';

export const TRACKS_PUBLISH_SUCCESS = 'TRACKS_PUBLISH_SUCCESS';
export const TRACKS_PUBLISH_FAILURE = 'TRACKS_PUBLISH_FAILURE';
export const TRACKS_UNPUBLISH_SUCCESS = 'TRACKS_UNPUBLISH_SUCCESS';
export const TRACKS_UNPUBLISH_FAILURE = 'TRACKS_UNPUBLISH_FAILURE';

export const ADD_GENRE_TRACKS_FILTER = 'ADD_GENRE_TRACKS_FILTER';
export const DELETE_GENRE_TRACKS_FILTER = 'DELETE_GENRE_TRACKS_FILTER';
export const ADD_COMPANY_TYPE_FILTER = 'ADD_COMPANY_TYPE_FILTER';
export const DELETE_COMPANY_TYPE_FILTER = 'DELETE_COMPANY_TYPE_FILTER';
export const SORT_TRACKS_BY = 'SORT_TRACKS_BY';
export const CHANGE_SORT_TRACKS_DIRECTION = 'CHANGE_SORT_TRACKS_DIRECTION';
export const REMOVE_TRACKS_SORT = 'REMOVE_TRACKS_SORT';

export const GET_GENRES_SUCCESS = 'GET_GENRES_SUCCESS';
export const GET_GENRES_FAILURE = 'GET_GENRES_FAILURE';

export const TOGGLE_TRACKS_VIEW = 'TOGGLE_TRACKS_VIEW';
export const TOGGLE_OPENED_TRACKS_FILTER = 'TOGGLE_OPENED_TRACKS_FILTER';
export const TOGGLE_TRACK_EDITOR = 'TOGGLE_TRACK_EDITOR';

export const RESET_STATISTIC_SUCCESS = 'RESET_STATISTIC_SUCCESS';
export const GET_STATISTIC_SUCCESS = 'GET_STATISTIC_SUCCESS';
export const GET_STATISTIC_FAILURE = 'GET_STATISTIC_FAILURE';

export const UPDATE_TRACK_START = 'UPDATE_TRACK_START';
export const UPDATE_TRACK_SUCCESS = 'UPDATE_TRACK_SUCCESS';
export const UPDATE_TRACK_FAILURE = 'UPDATE_TRACK_FAILURE';

export const UPLOAD_TRACKS_SUCCESS = 'UPLOAD_TRACKS_SUCCESS';
export const UPLOAD_TRACKS_FAILURE = 'UPLOAD_TRACKS_FAILURE';
export const UPLOAD_TRACKS_START = 'UPLOAD_TRACKS_START';

export const GET_COMPANY_TYPES_SUCCESS = 'GET_COMPANY_TYPES_SUCCESS';
export const GET_COMPANY_TYPES_FAILURE = 'GET_COMPANY_TYPES_FAILURE';

export const REQUEST_TRACKS_CHECK_SUCCESS = 'REQUEST_TRACKS_CHECK_SUCCESS';
export const REQUEST_TRACKS_CHECK_FAILURE = 'REQUEST_TRACKS_CHECK_FAILURE';

export const START_PLAYING_TRACK = 'START_PLAYING_TRACK';
export const STOP_PLAYING_TRACK = 'STOP_PLAYING_TRACK';
export const CLEAR_PLAYING_TRACK = 'CLEAR_PLAYING_TRACK';
export const LIMIT_TRACKS = 'LIMIT_TRACKS';
export const UPDATE_CURRENT_TIME_PLAYING_TRACK = 'UPDATE_CURRENT_TIME_PLAYING_TRACK';

export const UPDATE_SIDEBAR_ACCORDION_SLUG = 'UPDATE_SIDEBAR_ACCORDION_SLUG';

export function toggleLoader(show) {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_LOADER,
      payload: show
    });
  };
}

export function toggleFetchTracksLoader(show, clearTracks) {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_FETCH_TRACKS_LOADER,
      payload: {show, clearTracks}
    });
  };
}

export function fetchTracks(peaks, sortBy, direction, loadedTracks) {
  const oAuth = new Mubicon.OAuth();
  oAuth.accessToken = UserHelpers.getAccessToken();
  api.setDefaultAuthentication(oAuth);

  if (!loadedTracks) {
    loadedTracks = [];
  }

  let sorting = null;
  if (sortBy && sortBy !== '') {
    sorting = sortBy + '~' + direction;
  }

  return dispatch => {
    return api.getTracks([], [], loadedTracks.length, sorting).then((response) => {
      dispatch(toggleFetchTracksLoader(false));
      const allTracksLoaded = response.body.data.length < response.body.limit;
      dispatch(fetchAllTrackPeaks(response.body.data, peaks));
      return dispatch(tracksSuccess(loadedTracks.concat(response.body.data), allTracksLoaded));
    }).catch((err) => {
      dispatch(toggleFetchTracksLoader(false));
      return dispatch(tracksFailure(err));
    });
  };
}

export function tracksSuccess(tracks, allTracksLoaded) {
  return (dispatch) => {
    dispatch({
      type: GET_TRACKS_LIST_SUCCESS,
      payload: {
        data: tracks,
        allTracksLoaded
      }
    });
  };
}

export function tracksFailure(error) {
  return UserHelpers.handleAuthError(error.response, (dispatch) => {
    dispatch({
      type: GET_TRACKS_LIST_FAILURE,
      payload: error
    });
  });
}

export function fetchAllTrackPeaks(tracks=[], peaks={}) {
  return dispatch => {
    tracks.filter(t => !peaks || !peaks.hasOwnProperty(t.trackId)).reduce((previousFetch, nextTrack) => {
      return previousFetch.then(() => {
        return fetchTrackPeaksSimple(dispatch, nextTrack);
      });
    }, Promise.resolve());
  };
}

export function fetchTrackPeaks(track) {
  return dispatch => {
    return fetch(`${UrlHelpers.basePeaks()}/${track.fileHash}.json`).then(function (response) {
      return response.json();
    }).then((json) => {
      return dispatch(fetchPeaksSuccess(json, track.trackId));
    }).catch((err) => {
      return dispatch(fetchPeaksFailure(err));
    });
  };
}

export function fetchTrackPeaksSimple(dispatch, track) {
  return fetch(`${UrlHelpers.basePeaks()}/${track.fileHash}.json`).then(function (response) {
    return response.json();
  }).then((json) => {
    return dispatch(fetchPeaksSuccess(json, track.trackId));
  });
}

export function fetchPeaksSuccess(json, trackId) {
  return (dispatch) => {
    dispatch({
      type: FETCH_PEAKS_SUCCESS,
      payload: {data: json.data, trackId}
    });
  };
}

export function fetchPeaksFailure(err) {
  return (dispatch) => {
    dispatch({
      type: FETCH_PEAKS_FAILURE,
      payload: err
    });
  };
}

export function publishTrack(fileHash) {
  const oAuth = new Mubicon.OAuth();
  oAuth.accessToken = UserHelpers.getAccessToken();
  api.setDefaultAuthentication(oAuth);

  return dispatch => {
    return api.putTracksByFileHashPublish(fileHash).then((response) => {
      return dispatch(publishSuccess());
    }).catch((err) => {
      return dispatch(publishFailure(err));
    });
  };
}

function publishSuccess() {
  return (dispatch) => {
    UserHelpers.showSuccess("Task to enable track was sent");
    dispatch({
      type: TRACKS_PUBLISH_SUCCESS
    });
  };
}

function publishFailure(err) {
  return (dispatch) => {
    UserHelpers.showError("Error accured on sending task");
    dispatch({
      type: TRACKS_PUBLISH_FAILURE,
      payload: err
    });
  };
}

export function checkTracks() {
  const oAuth = new Mubicon.OAuth();
  oAuth.accessToken = UserHelpers.getAccessToken();
  api.setDefaultAuthentication(oAuth);

  return dispatch => {
    return api.putTracksCheck().then((response) => {
      return dispatch(checkTracksSuccess());
    }).catch((err) => {
      return dispatch(checkTracksFailure(err));
    });
  };
}

function checkTracksSuccess() {
  return (dispatch) => {
    dispatch({
      type: REQUEST_TRACKS_CHECK_SUCCESS
    });
  };
}

function checkTracksFailure(err) {
  return (dispatch) => {
    dispatch({
      type: REQUEST_TRACKS_CHECK_FAILURE,
      payload: err
    });
  };
}

export function unPublishTrack(fileHash) {
  const oAuth = new Mubicon.OAuth();
  oAuth.accessToken = UserHelpers.getAccessToken();
  api.setDefaultAuthentication(oAuth);

  return dispatch => {
    return api.putTracksByFileHashUnpublish(fileHash).then((response) => {
      return dispatch(unPublishSuccess());
    }).catch((err) => {
      return dispatch(unPublishFailure(err));
    });
  };
}

function unPublishSuccess() {
  return (dispatch) => {
    dispatch({
      type: TRACKS_UNPUBLISH_SUCCESS
    });
  };
}

function unPublishFailure(err) {
  return (dispatch) => {
    dispatch({
      type: TRACKS_UNPUBLISH_FAILURE,
      payload: err
    });
  };
}

export function toggleTracksView(view) {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_TRACKS_VIEW,
      payload: view
    });
  };
}

export function toggleOpenedTracksFilter(filter) {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_OPENED_TRACKS_FILTER,
      payload: filter
    });
  };
}

export function fetchGenres() {
  const oAuth = new Mubicon.OAuth();
  oAuth.accessToken = UserHelpers.getAccessToken();
  api.setDefaultAuthentication(oAuth);
  return dispatch => {
    return api.getGenres().then((genresData) => {
      return dispatch(genresSuccess(genresData));
    }).catch((err) => {
      return dispatch(genresFailure(err));
    });
  };
}

export function genresSuccess(response) {
  return (dispatch) => {
    dispatch({
      type: GET_GENRES_SUCCESS,
      payload: response.body
    });
  };
}

export function genresFailure(error) {
  return UserHelpers.handleAuthError(error.response, (dispatch) => {
    dispatch({
      type: GET_GENRES_FAILURE,
      payload: error
    });
  });
}

export function resetStatistic() {
  return (dispatch) => {
    dispatch({
      type: RESET_STATISTIC_SUCCESS
    });
  };
}

export function fetchStatistic(startDate, endDate) {
  const oAuth = new Mubicon.OAuth();
  oAuth.accessToken = UserHelpers.getAccessToken();
  api.setDefaultAuthentication(oAuth);

  return dispatch => {
    console.log("STATISTIC");
    return api.getStatistics(
      moment.utc(startDate).format(),
      moment.utc(endDate).format()
    ).then((statisticData) => {
      return dispatch(fetchStatisticSuccess(statisticData, startDate, endDate));
    }).catch((err) => {
      return dispatch(fetchStatisticFailure(err));
    });
  };
}

export function fetchStatisticSuccess(response, start, end) {
  return (dispatch) => {
    dispatch({
      type: GET_STATISTIC_SUCCESS,
      payload: {statistic: response.body, start, end}
    });
  };
}

export function fetchStatisticFailure(error) {
  return UserHelpers.handleAuthError(error.response, (dispatch) => {
    dispatch({
      type: GET_STATISTIC_FAILURE,
      payload: error
    });
  });
}

export function uploadTracks(tracks) {
  const oAuth = new Mubicon.OAuth();
  oAuth.accessToken = UserHelpers.getAccessToken();
  api.setDefaultAuthentication(oAuth);
  return dispatch => {
    dispatch(uploadTracksStart());
    return api.postUploadAudio(tracks).then((_) => {
      return dispatch(uploadTracksSuccess());
    }).catch((err) => {
      return dispatch(uploadTracksFailure(err));
    });
  };
}

export function uploadTracksStart() {
  return (dispatch) => {
    dispatch({
      type: UPLOAD_TRACKS_START,
    });
  };
}

export function uploadTracksSuccess() {
  return (dispatch) => {
    dispatch({
      type: UPLOAD_TRACKS_SUCCESS,
    });
  };
}

export function uploadTracksFailure(error) {
  return UserHelpers.handleAuthError(error.response, (dispatch) => {
    dispatch({
      type: UPLOAD_TRACKS_FAILURE,
      payload: error
    });
  });
}

export function updateTrack(track) {
  const oAuth = new Mubicon.OAuth();
  oAuth.accessToken = UserHelpers.getAccessToken();
  api.setDefaultAuthentication(oAuth);

  const {trackId, album, artist, title, isrc, year, genres} = track;

  return dispatch => {
    return api.putTracksUpdate(trackId, title, artist, album, genres, year, isrc);
  };
}

export function updateSidebarAccordionSlug(slug) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SIDEBAR_ACCORDION_SLUG,
      payload: { slug }
    });
  };
}

export function updateTrackStart(track) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_TRACK_START,
      payload: track
    });
  };
}

export function updateTrackSuccess(track) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_TRACK_SUCCESS,
      payload: track
    });
  };
}

export function updateTrackFailure(error) {
  return UserHelpers.handleAuthError(error.response, (dispatch) => {
    dispatch({
      type: UPDATE_TRACK_FAILURE,
      payload: error
    });
  });
}

export function fetchCompanyTypes() {
  const oAuth = new Mubicon.OAuth();
  oAuth.accessToken = UserHelpers.getAccessToken();
  api.setDefaultAuthentication(oAuth);
  return dispatch => {
    return api.getCompanies().then((data) => {
      return dispatch(companyTypesSuccess(data));
    }).catch((err) => {
      return dispatch(companyTypesFailure(err));
    });
  };
}

export function companyTypesSuccess(response) {
  return (dispatch) => {
    dispatch({
      type: GET_COMPANY_TYPES_SUCCESS,
      payload: response.body
    });
  };
}

export function companyTypesFailure(error) {
  return UserHelpers.handleAuthError(error.response, (dispatch) => {
    dispatch({
      type: GET_COMPANY_TYPES_FAILURE,
      payload: error
    });
  });
}

export function addGenreFilter(genre) {
  return (dispatch) => {
    dispatch({
      type: ADD_GENRE_TRACKS_FILTER,
      payload: genre
    });
  };
}

export function deleteGenreFilter(genre) {
  return (dispatch) => {
    dispatch({
      type: DELETE_GENRE_TRACKS_FILTER,
      payload: genre
    });
  };
}

export function addCompanyTypeFilter(companyType) {
  return (dispatch) => {
    dispatch({
      type: ADD_COMPANY_TYPE_FILTER,
      payload: companyType
    });
  };
}

export function deleteCompanyTypeFilter(companyType) {
  return (dispatch) => {
    dispatch({
      type: DELETE_COMPANY_TYPE_FILTER,
      payload: companyType
    });
  };
}

export function changeSortBy(sortBy) {
  return (dispatch) => {
    dispatch({
      type: SORT_TRACKS_BY,
      payload: sortBy
    });
  };
}

export function changeTracksSortDirection(direction) {
  return (dispatch) => {
    dispatch({
      type: CHANGE_SORT_TRACKS_DIRECTION,
      payload: direction
    });
  };
}

export function removeTracksSort() {
  return (dispatch) => {
    dispatch({
      type: REMOVE_TRACKS_SORT
    });
  };
}

export function startPlayingTrack(playingTrack, wavesurfer, playingTrackSource, trackIndex) {
  return (dispatch) => {
    dispatch({
      type: START_PLAYING_TRACK,
      playingTrack: playingTrack,
      wavesurfer: wavesurfer,
      playingTrackSource: playingTrackSource,
      playingTrackIndex: trackIndex
    });
  };
}

export function stopPlayingTrack() {
  return (dispatch) => {
    dispatch({
      type: STOP_PLAYING_TRACK
    });
  };
}

export function clearPlayingTrack(playingWavesurfer) {
  if (playingWavesurfer) {
    playingWavesurfer.stop();
    playingWavesurfer.destroy();
    playingWavesurfer = null;
  }
  return (dispatch) => {
    dispatch({
      type: CLEAR_PLAYING_TRACK
    });
  };
}

export function limitTracks(tracks) {
  if (!tracks) return;
  if (tracks.length > 30)
    tracks = tracks.slice(0, 30);
  return (dispatch) => {
    dispatch({
      type: LIMIT_TRACKS,
      payload: tracks
    });
  };
}

export function updateCurrentTimeForPlayingTrack(time) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_CURRENT_TIME_PLAYING_TRACK,
      time: time
    });
  };
}
