import React from 'react';

export class AccordionSidebarItem extends React.Component {

  constructor(props, context) {
    super(props, context);

    this.handleAccordionItemClick = this.handleAccordionItemClick.bind(this);
  }

  handleAccordionItemClick(slug, e) {
    this.props.handleAccordionItemClick(slug);
  }

  render() {
    let {accordionItemTitle, activeItemSlug, slug, children, icon} = this.props;
    const isActive = activeItemSlug === slug;
    return (
      <div className={"accordion__item " + (isActive ? 'active' : '')}>
        <div className="accordion__sidebar_header" onClick={this.handleAccordionItemClick.bind(this, slug)}>
          <div className="right-heading right-heading_border-dark">
            <span className="right-heading__icon right-heading__icon_calendar">
              {icon}
            </span>
            <span className="right-heading__text">{accordionItemTitle}</span>
          </div>
        </div>
        <div className="accordion__sidebar_body" style={{display: isActive ? 'block' : 'none' }}>{children}</div>
      </div>
    )
  }
}
