import React from 'react';
import Tabs from '../Layout/Tabs';
import Tab from '../Layout/Tab';
import Finance from './Finance';
import Tickets from './Tickets';
import AccessSettings from './AccessSettings';
import CompanyInfo from './CompanyInfo';

export default class AccountTabs extends React.Component
{
  render() {

    return (
        <Tabs>
            <Tab linkName="Сброс пароля">
                <AccessSettings/>
            </Tab>
            <Tab linkName="Сведения о компании">
                <CompanyInfo fetchUserFullInfo={this.props.fetchUserFullInfo} userInfo={this.props.userInfo}/>
            </Tab>
            <Tab linkName="Тикеты">
                <Tickets />
            </Tab>
            <Tab linkName="Documents">
                <Finance />
            </Tab>
        </Tabs>
    );
  }
};
