import React, { Component } from 'react';
import StatisticCalendar from "./StatisticCalendar";
import {renderField} from "../../renderField";
import StatisticHelpers from "../../../helpers/Statistic";
import UserHelpers from "../../../helpers/User";

export default class StatisticModule extends Component {

  constructor(props, context) {
    super(props, context);
    this.changeDateRange = this.changeDateRange.bind(this);
    this.createTicket = this.createTicket.bind(this);
    this.state = {};
  }

  componentDidMount() {
    const {start, end} = this.props;
    this.props.fetchOwner();
    this.props.fetchStatistic(start, end);
  }

  changeDateRange(start, end) {
    if (!start || !end) {
      return;
    }

    const {fetchStatistic} = this.props;
    this.props.clearTicket();
    fetchStatistic(start, end);
  }

  createTicket(e) {
    e.preventDefault();
    const {owner, start, end} = this.props;
    const header = `Запрос статистики ${owner.name}`;
    const msg = `Owner: ${owner.name}; <br/>Start: ${StatisticHelpers.RenderDate(start)}; <br/>End: ${StatisticHelpers.RenderDate(end)};`;
    const ticketType = 10;
    if (msg && msg !== '' && header !== '') {
      this.props.createTicket(header, msg, ticketType);
      setTimeout(this.props.clearTicket, 5000);
    }
  }

  render() {
    const {owner, start, end, statistic, ticket} = this.props;
    const amount =
      (owner && owner.price && statistic && statistic.amount ? owner.price * statistic.amount : 0.0)
      .toFixed(2);

    return (
      <span>
        <StatisticCalendar
          changeDateRange={this.changeDateRange}
          start={start}
          end={end}
        />
        <div className="statistic__info">
          <div className="calendar-controls calendar-container__result calendar-container__result_value">
            { renderField({
              type: 'text',
              name: 'total',
              disabled: true,
              input: {value: `Заработок: ${amount}`}})
            }
            {!ticket &&
            <button className="button button_size_big button_color_white button_weight_normal account__button"
                    type="submit"
                    onClick={this.createTicket}>
              ЗАПРОСИТЬ СТАТАТИСТИКУ
            </button>
            }
            {ticket &&
            <button className="button button_size_big button_color_light-grey button_weight_normal account__button"
                    type="submit"
                    disabled={true}
                    onClick={this.createTicket}>
              ЗАПРОС ОТПРАВЛЕН
            </button>
            }
          </div>
        </div>
      </span>
    )
  }
}
