import React from 'react';
import PropTypes from 'prop-types';
import HeaderPlayer from '../Catalog/Player/HeaderPlayer';
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";

class MainHeader extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      width: 0
    };
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  updateDimensions() {
    const header = document.getElementsByClassName('layout__header')[0];
    if (header) {
      this.setState({width: header.offsetWidth});
    }
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  render() {
    const {width} = this.state;
    const {generalHeaderFeed} = this.props;
    return (
      <header className="layout__header">
          <div className="header" style={{width: width + 'px' }}>
            {
              <HeaderPlayer/>
            }
          </div>
      </header>
    );
  }
}

MainHeader.propTypes = {
  headerText: PropTypes.string
};

export default connect(state => ({}))(MainHeader);

