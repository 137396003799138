import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {AccordionSidebarItem} from './AccordionSidebarItem.js';

import {ReactComponent as MenuIcon} from '../../icons/menu.svg';

export default class AccordionSidebar extends Component {

    constructor(props, context) {
        super(props, context);
        this.handleAccordionItemClick = this.handleAccordionItemClick.bind(this);
    }

    handleAccordionItemClick(slug) {
      let activeItemSlug = null;
      if (this.props.activeItemSlug !== slug) {
        activeItemSlug = slug
      }
      this.props.updateSlug(activeItemSlug);
    }

    render() {
      const {activeItemSlug} = this.props;
      const self = this;
      return (
        <div className="accordion">
          <div className="accordion__items">
            {this.props.children
              .filter(child => child.props.enabled !== false)
              .map((child, i) => (
              <AccordionSidebarItem
                icon={child.props.icon}
                handleAccordionItemClick={self.handleAccordionItemClick}
                accordionItemTitle={child.props.title}
                activeItemSlug={activeItemSlug}
                slug={child.props.slug}
                key={i}
              >
                {child.props.children}
              </AccordionSidebarItem>
            ))}
          </div>
        </div>
      )
    }
}
AccordionSidebar.propTypes = {
  activeItemSlug: PropTypes.string
};
