import React from 'react';
import { reduxForm, Field, formValues } from 'redux-form';
import {renderField} from '../renderField';
import {updateCompanyInfo, updateCompanyInfoSuccess, updateCompanyInfoFailure} from '../../actions/users';
import connect from "react-redux/es/connect/connect";
import renderInputField from "../renderInputField";
import formValueSelector from "redux-form/es/formValueSelector";

import {ReactComponent as CheckIcon} from '../../icons/check.svg';

//TODO:: add validation
function validate(values) {
  var errors = {};
  var hasErrors = false;
  if (!values.userName || values.userName.trim() === '') {
    errors.username = 'Enter username';
    hasErrors = true;
  }
  if (!values.name || values.name.trim() === '') {
    errors.name = 'Enter name';
    hasErrors = true;
  }
  return hasErrors && errors;
}

const validateAndSaveInfo = (values, dispatch) => {
  return dispatch(updateCompanyInfo(values)).then((result) => {
          return dispatch(updateCompanyInfoSuccess(result.body));
        }).catch((error) => {
          return dispatch(updateCompanyInfoFailure(error.response.body));
        });
};

class UpdateCompanyInfoForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.firstLoad = true;
    this.setPostalAddressAsActual = this.setPostalAddressAsActual.bind(this);
    this.setActualAddressTheSameAsLegal = this.setActualAddressTheSameAsLegal.bind(this);
    this.onActualAddressChanged = this.onActualAddressChanged.bind(this);
    this.onLegalAddressChanged = this.onLegalAddressChanged.bind(this);
    this.onPostalAddressChanged = this.onPostalAddressChanged.bind(this);

    this.state = {
      postalTheSameAsActual: true,
      actualTheSameAsLegal: false,
      addressActual: '',
      addressLegal: ''
    };
  }

  componentDidMount() {
    this.props.fetchUserFullInfo();
  }

  componentWillReceiveProps(nextProps) {
    if (this.firstLoad && Object.keys(nextProps.userInfo).length > 0) {
      this.firstLoad = false;
      const postalTheSameAsActual = nextProps.userInfo.addressActual ?
        nextProps.userInfo.addressActual === nextProps.userInfo.addressPostal : false;
      const actualTheSameAsLegal = nextProps.userInfo.addressLegal ?
        nextProps.userInfo.addressActual === nextProps.userInfo.addressLegal : false;
      this.setState({ postalTheSameAsActual,
                      actualTheSameAsLegal,
                      addressActual: nextProps.userInfo.addressActual,
                      addressLegal: nextProps.userInfo.addressLegal }, () => {
        this.props.initialize(nextProps.userInfo);
      });
    }
  }

  setPostalAddressAsActual(e) {
    const {change} = this.props;
    const checked = e.target.checked;
    this.setState({
      postalTheSameAsActual: checked,
      addressPostal: this.state.addressActual
    }, () => {
      if (checked) {
        change('addressPostal', this.state.addressActual);
      }
    });
  }

  onActualAddressChanged(e) {
    const addressActual = e.target.value;
    this.setState({addressActual: addressActual,
      postalTheSameAsActual: addressActual  === this.state.addressPostal,
      actualTheSameAsLegal: addressActual  === this.state.addressLegal});
  }

  onLegalAddressChanged(e) {
    const legalAddress = e.target.value;
    this.setState({addressLegal: legalAddress, actualTheSameAsLegal: legalAddress === this.state.addressActual});
  }

  onPostalAddressChanged(e) {
    const addressPostal = e.target.value;
    this.setState({addressPostal: addressPostal, postalTheSameAsActual: addressPostal  === this.state.addressActual});
  }

  setActualAddressTheSameAsLegal(e) {
    const {change} = this.props;
    const checked = e.target.checked;
    this.setState({
      actualTheSameAsLegal: checked,
      addressActual: this.state.addressLegal
    }, () => {
      if (checked) {
        change('addressActual', this.state.addressLegal);
      }
    });
  }

  render() {
    const {handleSubmit, invalid, submitting} = this.props;

    return (
        <form className="account__form" method="post" onSubmit={ handleSubmit(validateAndSaveInfo) }>

          <Field
            name="legalFormFull"
            type="text"
            component={ renderField }
            label="Организационно-правовая форма"
            placeholder="Общество с ограниченной ответсвенностью" />

          <Field
            name="legalFormShort"
            type="text"
            component={ renderField }
            label="Организационно-правовая форма (сокр.)"
            placeholder="ООО" />

          <Field
            name="companyName"
            type="text"
            component={ renderField }
            label="Наименование юр. лица"
            placeholder="" />

          <Field
            name="signerFIO"
            type="text"
            component={ renderField }
            label="ФИО подписанта"
            placeholder="Петров Александр Григорьевич" />

          <Field
            name="emailFinancial"
            type="text"
            component={ renderField }
            label="Электронная почта для финансовых документов"
            placeholder="" />

          <Field
            name="contractNumber"
            type="text"
            component={ renderField }
            label="Контактный телефон"
            placeholder="" />

          <Field
            name="INN"
            type="text"
            component={ renderField }
            label="ИНН"
            placeholder="123456767" />

          <Field
            name="OGRN"
            type="text"
            component={ renderField }
            label="ОГРН"
            placeholder="61274671926479124" />

          <Field
            name="KPP"
            type="text"
            component={ renderField }
            label="КПП"
            placeholder="123456767" />

           <Field
            name="addressLegal"
            type="text"
            component={ renderField }
            label="Юридический адрес"
            placeholder="61274671926479124"
            onChange={this.onLegalAddressChanged} />

            <div className="inputbox">
                <div className="inputbox__left">
                    <p className="text-small">Юридический адрес</p>
                </div>
                <div className="inputbox__right">
                  <Field
                    name="addressActual"
                    type="text"
                    component={ renderInputField }
                    placeholder=""
                    onChange={this.onActualAddressChanged}
                  />

                </div>
            </div>

            <div className="inputbox inputbox_header">
                <div className="inputbox__left">
                    <p className="text-small">
                        <b>Платежные данные</b>
                    </p>
                </div>
                <div className="inputbox__right" />
            </div>

            <Field
                 name="bankPayAccount"
                 type="text"
                 component={ renderField }
                 label="Рассчетный счет"
                 placeholder="400521203012301301203" />

            <Field
                 name="bankCorrAccount"
                 type="text"
                 component={ renderField }
                 label="Корр. счет"
                 placeholder="34121412412412431231231" />

            <Field
                 name="bankName"
                 type="text"
                 component={ renderField }
                 label="Наименование банка"
                 placeholder="ПАО “Банк Открытие”" />

            <Field
                 name="bankBIK"
                 type="text"
                 component={ renderField }
                 label="БИК"
                 placeholder="041231231" />


            <div className="inputbox inputbox_header">
                <div className="inputbox__left">
                    <p className="text-small">
                        <b />
                    </p>
                </div>
                <div className="inputbox__right">
                    <button className="button button_color_blue button_size_big button_weight_normal account__button float-right"
                            type="submit"
                            disabled={invalid || submitting}
                    >СОХРАНИТЬ</button>
                </div>
            </div>
        </form>
    );
  }
}

UpdateCompanyInfoForm = reduxForm({
  form: 'UpdateCompanyInfoForm',
  validate
})(UpdateCompanyInfoForm);

export default connect(
  state => ({
    initialValues: state.user.userInfo
  })
)(UpdateCompanyInfoForm)



