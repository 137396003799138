import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";

import {ReactComponent as CalendarIcon} from '../../../icons/date_range.svg';
import {ReactComponent as UploadIcon} from '../../../icons/cloud_upload.svg';
import {ReactComponent as CheckIcon} from '../../../icons/check.svg';
import {ReactComponent as EditIcon} from '../../../icons/edit-black.svg';
import AccordionSidebar from "../../Layout/AccordionSidebar";
import UploadFileModule from "./UploadFileModule";
import StatisticModule from "./StatisticModule";
import EditTrackModule from "./EditTrackModule";
import TracksCheckModule from "./TracksCheckModule";

class InformationRightSidebar extends Component {

  constructor(props, context) {
    super(props, context);
    this.firstLoad = true;
    this.state = {};
  }

  componentDidMount() {}

  componentWillReceiveProps() {
    this.firstLoad = false;
  }

  render() {
    let {uploadTracksActive, uploadTracksResult, fetchStatistic, fetchOwner, fetchGenres, owner, user, createTicket, clearTicket,
         updateSidebarAccordionSlug, checkTracks,
         genres, ticket, statistic, statisticStart, statisticEnd, editingTrack, sidebarSlug, uploadTracks} = this.props;

    return (
      <span>
        <AccordionSidebar
          activeItemSlug={sidebarSlug}
          updateSlug={updateSidebarAccordionSlug}
        >
          <div title="Статистика" slug="stats" icon={<CalendarIcon className="svg-icon"/>}>
            <StatisticModule
              fetchStatistic={fetchStatistic}
              fetchOwner={fetchOwner}
              owner={owner}
              ticket={ticket}
              createTicket={createTicket}
              clearTicket={clearTicket}
              statistic={statistic}
              start={statisticStart}
              end={statisticEnd}
            />
          </div>
          <div title="Редактирование" slug="edit" enabled={editingTrack['fileHash'] !== undefined} icon={<EditIcon className="svg-icon"/>}>
            <EditTrackModule
              editingTrack={editingTrack}
              fetchGenres={fetchGenres}
              genres={genres}
            />
          </div>
          <div title="Загрузка" slug="upload" icon={<UploadIcon className="svg-icon"/>}>
            <UploadFileModule
              owner={owner}
              ticket={ticket}
              createTicket={createTicket}
              clearTicket={clearTicket}
              uploadTracks={uploadTracks}
              uploadTracksActive={uploadTracksActive}
              uploadTracksResult={uploadTracksResult}
            />
          </div>
          <div title="Проверка" slug="check" icon={<CheckIcon className="svg-icon"/>}>
            <TracksCheckModule
              checkTracks={checkTracks}
            />
          </div>
        </AccordionSidebar>
      </span>
    )
  }
}

export default connect(state => ({
  ticket: state.user.ticket,
  user: state.user.user,
  owner: state.user.owner,
  statistic: state.catalog.statistic,
  genres: state.catalog.genres,
  statisticStart: state.catalog.statisticStart,
  statisticEnd: state.catalog.statisticEnd,
  uploadTracksActive: state.catalog.uploadTracksActive,
  uploadTracksResult: state.catalog.uploadTracksResult,
  editingTrack: state.catalog.editingTrack,
  sidebarSlug: state.catalog.sidebarSlug
}))(InformationRightSidebar);
