import {api} from '../index';
import UserHelpers from "../helpers/User";
import Alert from "react-s-alert";

//Sign Up User
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNUP_USER_FAILURE = 'SIGNUP_USER_FAILURE';
export const RESET_USER = 'RESET_USER';

export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNIN_USER_FAILURE = 'SIGNIN_USER_FAILURE';

//log out user
export const LOGOUT_USER = 'LOGOUT_USER';

export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE';

export const UPDATE_COMPANY_INFO_SUCCESS = 'UPDATE_COMPANY_INFO_SUCCESS';
export const UPDATE_COMPANY_INFO_FAILURE = 'UPDATE_COMPANY_INFO_FAILURE';

export const FETCH_USER_FULL_INFO_SUCCESS = 'FETCH_USER_FULL_INFO_SUCCES';
export const FETCH_USER_FULL_INFO_FAILURE = 'FETCH_USER_FULL_INFO_FAILURE';

export const CREATE_TICKET_SUCCESS = 'CREATE_TICKET_SUCCESS';
export const CREATE_TICKET_FAILURE = 'CREATE_TICKET_FAILURE';

export const FETCH_OWNER_SUCCESS = 'FETCH_OWNER_SUCCESS';
export const FETCH_OWNER_FAILURE = 'FETCH_OWNER_FAILURE';

export const CLEAR_TICKET = 'CLEAR_TICKET';

const Mubicon = require('@mubicon/api');

export function signInUser(formValues) {
    const opts = new Mubicon.CredentialsViewModel();
    opts.login = formValues.username;
    opts.password = formValues.password;
    return (dispatch) => {
      return api.postSessionLogin(opts);
    };
}


export function signInUserSuccess(user) {
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: user
  };
}

export function signInUserFailure(error) {
  error.response.body.errors && error.response.body.errors.forEach((e) => {
    UserHelpers.showError(e['errorMessage']);
  });
  return {
    type: SIGNIN_USER_FAILURE,
    payload: error
  };
}

export function logoutUser() {
  return {
    type: LOGOUT_USER
  };
}

export function fetchOwner() {
  const oAuth = new Mubicon.OAuth();
  oAuth.accessToken = UserHelpers.getAccessToken();
  api.setDefaultAuthentication(oAuth);
  return dispatch => {
    return api.getUsersOwner().then(resp => {
      return dispatch(fetchOwnerSuccess(resp.body));
    }).catch(err => {
      return dispatch(fetchOwnerFailure(err));
    });
  };
}

function fetchOwnerSuccess(data) {
  return (dispatch) => {
    dispatch({
      type: FETCH_OWNER_SUCCESS,
      payload: data
    });
  };
}

function fetchOwnerFailure(err) {
  return (dispatch) => {
    dispatch({
      type: FETCH_OWNER_FAILURE,
      payload: err
    });
  };
}

export function updatePassword(formValues) {
  const oAuth = new Mubicon.OAuth();
  oAuth.accessToken = UserHelpers.getAccessToken();
  api.setDefaultAuthentication(oAuth);
  return dispatch => {
    return api.postUsersPasswordReset();
  };
}

export function updatePasswordSuccess() {
  // UserHelpers.showSuccess('Пароль успешно изменен.');
  return {
    type: UPDATE_PASSWORD_SUCCESS
  };
}

export function  updatePasswordFailure(response) {
  response.body.errors.forEach((error) => {
    UserHelpers.showError(error.errorMessage);
  });
  return UserHelpers.handleAuthError(response, {
    type: UPDATE_PASSWORD_FAILURE,
    payload: response.body,
  });
}

export function updateCompanyInfo(formValues) {
  const oAuth = new Mubicon.OAuth();
  oAuth.accessToken = UserHelpers.getAccessToken();
  api.setDefaultAuthentication(oAuth);
  let data = UserHelpers.fetchUserInfoFromToken();

  return dispatch => {
    return api.putUsers(data.id,
      formValues.name,
      data['unique_name'][1],
      data.email,
      formValues.phoneNumber,
      formValues.imageFile,
      formValues.objectAddress,
      formValues.objectTitle,
      formValues.emailFinancial,
      formValues.companyName,
      formValues.contractNumber,
      formValues.contractDate,
      formValues.bankName,
      formValues.bankBIK,
      formValues.bankCorrAccount,
      formValues.bankPayAccount,
      formValues.signerFIO,
      formValues.signerJob,
      formValues.signerDoc,
      formValues.legalFormShort,
      formValues.legalFormFull,
      formValues.addressLegal,
      formValues.addressPostal,
      formValues.addressActual,
      formValues.INN,
      formValues.KPP,
      formValues.OGRN,
      formValues.companyTypeUsers);
  };
}

export function fetchUserFullInfo() {
  const oAuth = new Mubicon.OAuth();
  oAuth.accessToken = UserHelpers.getAccessToken();
  api.setDefaultAuthentication(oAuth);

  return dispatch => {
    return api.getUsersFull().then((data) => {
      return dispatch(fetchUserFullInfoSuccess(data));
    }).catch((err) => {
      return dispatch(fetchUserFullInfoFailure(err));
    });
  };
}

export function fetchUserFullInfoSuccess(data) {
  return (dispatch) => {
    dispatch({
      type: FETCH_USER_FULL_INFO_SUCCESS,
      payload: data.body
    });
  };
}

export function fetchUserFullInfoFailure(err) {
  err.response.body && err.response.body.errors.forEach((e) => {
    UserHelpers.showError(e['errorMessage']);
  });
  return (dispatch) => {
    dispatch({
      type: FETCH_USER_FULL_INFO_FAILURE,
      payload: err
    });
  };
}

export function updateCompanyInfoSuccess() {
  UserHelpers.showSuccess('Информация о компании успешно изменена.');
  return {
    type: UPDATE_COMPANY_INFO_SUCCESS
  };
}

export function  updateCompanyInfoFailure(error) {
  error.response.body.errors.forEach(function(e) {
    UserHelpers.showError(e['errorMessage']);
  });
  return UserHelpers.handleAuthError(error, {
    type: UPDATE_COMPANY_INFO_FAILURE,
    payload: error
  });
}

export function createTicket(header, message, ticketType) {
  const oAuth = new Mubicon.OAuth();
  oAuth.accessToken = UserHelpers.getAccessToken();
  api.setDefaultAuthentication(oAuth);

  return dispatch => {
    return api.postTickets(header, message, ticketType).then((data) => {
      return dispatch(createTicketSuccess(data));
    }).catch((err) => {
      return dispatch(createTicketFailure(err));
    });
  };
}

export function createTicketSuccess(response) {
  // UserHelpers.showSuccess('Ваш запрос успешно создан.');
  return {
    type: CREATE_TICKET_SUCCESS,
    payload: response.body
  };
}

export function createTicketFailure(error) {
  error.response.body.errors.forEach((e) => {
    UserHelpers.showError(e['errorMessage']);
  });
  return UserHelpers.handleAuthError(error, {
    type: CREATE_TICKET_FAILURE,
    payload: error
  });
}

export function clearTicket() {
  return {
    type: CLEAR_TICKET
  };
}
