import React from 'react';
import IconMenu from './IconMenu.js';
import User from '../../helpers/User';
import { Link } from 'react-router-dom';
import connect from "react-redux/es/connect/connect";

class LeftSidebar extends React.Component
{
  render() {
    return (
      <span>
        {
          <Link to="/" className="sidebar__logo">
            <img src={require('../../images/logo.png')} alt="Mubicon logo"/>
          </Link>
        }
        { User.isLoggedin() &&
          <IconMenu />
        }
      </span>
    );
  }
};
export default connect(state => ({}), null)(LeftSidebar);
