import React from 'react';

import {ReactComponent as HelpIcon} from '../../icons/help_outline.svg';

export default class HelpSidebar extends React.Component
{
  render() {
    return (
      <div>
          <div className="right-heading">
              <span className="right-heading__icon right-heading__icon_calendar">
                  <HelpIcon className="svg-icon" />
              </span>
              <span className="right-heading__text">Помощь</span>
          </div>
          <div className="playlist-info">
              <p className="playlist-info__hint nomargintop">Внимательно ознакомьтесь с ответами на вопросы, которые могут возникнуть при работе с сервисом. Сообщение будет адресовано в нужный отдел. Специалист компании свяжестя по Вашему административному электронному адресу, который был указан
                  при подключении.</p>
          </div>
      </div>
    )
  }
}

