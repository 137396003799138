import {
  ADD_COMPANY_TYPE_FILTER,
  ADD_GENRE_TRACKS_FILTER,
  CHANGE_SORT_TRACKS_DIRECTION,
  CLEAR_PLAYING_TRACK,
  DELETE_COMPANY_TYPE_FILTER,
  DELETE_GENRE_TRACKS_FILTER,
  FETCH_PEAKS_SUCCESS,
  GET_COMPANY_TYPES_FAILURE,
  GET_COMPANY_TYPES_SUCCESS,
  GET_GENRES_FAILURE,
  GET_GENRES_SUCCESS,
  GET_STATISTIC_FAILURE,
  GET_STATISTIC_SUCCESS,
  GET_TRACKS_LIST,
  GET_TRACKS_LIST_FAILURE,
  GET_TRACKS_LIST_SUCCESS,
  LIMIT_TRACKS,
  REMOVE_TRACKS_SORT,
  RESET_STATISTIC_SUCCESS,
  SORT_TRACKS_BY,
  START_PLAYING_TRACK,
  STOP_PLAYING_TRACK,
  TOGGLE_FETCH_TRACKS_LOADER,
  TOGGLE_LOADER,
  TOGGLE_OPENED_TRACKS_FILTER,
  TOGGLE_TRACKS_VIEW,
  TRACKS_PUBLISH_FAILURE,
  TRACKS_PUBLISH_SUCCESS,
  TRACKS_UNPUBLISH_FAILURE,
  TRACKS_UNPUBLISH_SUCCESS,
  UPDATE_CURRENT_TIME_PLAYING_TRACK,
  UPDATE_TRACK_FAILURE,
  UPDATE_TRACK_START,
  UPDATE_TRACK_SUCCESS,
  UPLOAD_TRACKS_FAILURE,
  UPLOAD_TRACKS_START,
  UPLOAD_TRACKS_SUCCESS,
  UPDATE_SIDEBAR_ACCORDION_SLUG,
  REQUEST_TRACKS_CHECK_SUCCESS,
  REQUEST_TRACKS_CHECK_FAILURE
} from '../actions/catalog';
import StatisticHelpers from "../helpers/Statistic";

const INITIAL_STATE = {tracksLoading: true, loading: false, tracks: [], allTracksLoaded: true, peaks: {}, peaksLength: 0,
                        tracksView: 'grid', genres: [], companyTypes: [], tracksFilter: '',
                        statistic: {},
                        statisticStart: StatisticHelpers.DefaultStart,
                        statisticEnd: StatisticHelpers.DefaultEnd,
                        uploadTracksActive: false, uploadTracksResult: false, editingTrack: {}, sidebarIndex: 0,
                        sidebarSlug: 'stats',
                        genresFilter: [], companyTypeFilter: [], sortTracksBy: '', tracksSortDirection: 'asc',
                        playingWavesurfer: null, playingTrack: {}, isPlayingTrackActive: false, playingTrackSource: null,
                        currentTimePlayingTrack: 0, playingTrackIndex: -1};

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    case TOGGLE_FETCH_TRACKS_LOADER:
      return action.payload.clearTracks ?
        {...state, tracksLoading: action.payload.show, tracks: []} :
        {...state, tracksLoading: action.payload.show};
    case TOGGLE_LOADER:
      return {...state, loading: action.payload};
    case GET_TRACKS_LIST:
      return { ...state, tracks: [], error: null};
    case GET_TRACKS_LIST_SUCCESS:
      return { ...state, tracks: action.payload.data, error: null,
        allTracksLoaded: action.payload.allTracksLoaded};
    case GET_TRACKS_LIST_FAILURE:
      return { ...state, tracks: [], error: action.payload};
    case UPDATE_TRACK_START:
      return { ...state, editingTrack: action.payload, sidebarSlug: 'edit'};
    case UPDATE_TRACK_SUCCESS:
      let newTracks = state.tracks;
      const trackIndex = state.tracks.map(t => t.trackId).indexOf(action.payload.trackId);
      if (trackIndex >= 0) {
        const {title, album, artist, year, isrc, trackId, genres} = action.payload;
        const track = {...{}, ...state.tracks[trackIndex]};
        newTracks[trackIndex] = {...track, trackId, title, album, artist, year, isrc, genreTracks: genres};
        return { ...state, tracks: newTracks, editingTrack: {}, sidebarSlug: 'stats'};
      }
      return { ...state, editingTrack: {}, sidebarSlug: 'stats'};
    case UPDATE_TRACK_FAILURE:
      return { ...state, editingTrack: {}, sidebarSlug: 'edit'};
    case REQUEST_TRACKS_CHECK_SUCCESS:
      return { ...state};
    case REQUEST_TRACKS_CHECK_FAILURE:
      return { ...state};
    case UPLOAD_TRACKS_START:
      return { ...state, uploadTracksActive: true };
    case UPLOAD_TRACKS_SUCCESS:
      return { ...state, uploadTracksResult: true, uploadTracksActive: false, uploadError: null };
    case UPLOAD_TRACKS_FAILURE:
      return { ...state, uploadTracksResult: false, uploadTracksActive: false, uploadError: action.payload };

    case UPDATE_SIDEBAR_ACCORDION_SLUG:
      return { ...state, sidebarSlug: action.payload.slug };

    case FETCH_PEAKS_SUCCESS:
      let peaks = Object.assign({}, state.peaks);
      peaks[action.payload.trackId] = action.payload.data;
      return { ...state, peaks: peaks, peaksLength: Object.keys(peaks).length};
    case ADD_GENRE_TRACKS_FILTER:
      let genresFilter = state.genresFilter;
      genresFilter.push(action.payload);
      return { ...state, genresFilter: genresFilter};
    case DELETE_GENRE_TRACKS_FILTER:
      let deleteGenresFilter = state.genresFilter;
      let index = deleteGenresFilter.indexOf(action.payload);
      if (index !== -1) deleteGenresFilter.splice(index, 1);
      return { ...state, genresFilter: deleteGenresFilter};

    case ADD_COMPANY_TYPE_FILTER:
      let companyTypeFilter = state.companyTypeFilter;
      companyTypeFilter.push(action.payload);
      return { ...state, companyTypeFilter: companyTypeFilter};

    case DELETE_COMPANY_TYPE_FILTER:
      let deleteCompanyTypeFilter = state.companyTypeFilter;
      let companyTypeIndex = deleteCompanyTypeFilter.indexOf(action.payload);
      if (companyTypeIndex !== -1) deleteCompanyTypeFilter.splice(companyTypeIndex, 1);
      return { ...state, companyTypeFilter: deleteCompanyTypeFilter};

    case SORT_TRACKS_BY:
      return { ...state, sortTracksBy: action.payload, tracksSortDirection: 'asc'};

    case CHANGE_SORT_TRACKS_DIRECTION:
      return { ...state, tracksSortDirection: action.payload};

    case REMOVE_TRACKS_SORT:
      return { ...state, sortTracksBy: '', tracksSortDirection: 'asc'};

    case TOGGLE_TRACKS_VIEW:
      return { ...state, tracksView: action.payload, loading: false};
    case TOGGLE_OPENED_TRACKS_FILTER:
      return { ...state, tracksFilter: action.payload};

    case TRACKS_PUBLISH_SUCCESS:
      return { ...state, tracks: action.payload.tracks};
    case TRACKS_PUBLISH_FAILURE:
      return { ...state, error: action.payload };
    case TRACKS_UNPUBLISH_SUCCESS:
      return { ...state, tracks: action.payload.tracks};
    case TRACKS_UNPUBLISH_FAILURE:
      return { ...state, error: action.payload };

    case GET_GENRES_SUCCESS:
      return { ...state, genres: action.payload};
    case GET_GENRES_FAILURE:
      return { ...state, genres: [], error: action.payload};
    case RESET_STATISTIC_SUCCESS:
      return { ...state, statistic: {}, statisticStart: null, statisticEnd: null };
    case GET_STATISTIC_SUCCESS:
      return {...state,
        statistic: action.payload.statistic,
        statisticStart: action.payload.start,
        statisticEnd: action.payload.end};
    case GET_STATISTIC_FAILURE:
      return { ...state, statistic: {}, error: action.payload};

    case GET_COMPANY_TYPES_SUCCESS:
      return { ...state, companyTypes: action.payload};
    case GET_COMPANY_TYPES_FAILURE:
      return { ...state, companyTypes: [], error: action.payload};

    case START_PLAYING_TRACK:
      return { ...state, playingWavesurfer: action.wavesurfer,
        playingTrack: action.playingTrack,
        isPlayingTrackActive: true,
        playingTrackSource: action.playingTrackSource,
        playingTrackIndex: action.playingTrackIndex
      };

    case STOP_PLAYING_TRACK:
      return { ...state, isPlayingTrackActive: false, loading: false};

    case CLEAR_PLAYING_TRACK:
      return { ...state, isPlayingTrackActive: false, playingTrack: {}, playingWavesurfer: null};

    case LIMIT_TRACKS:
      return { ...state, tracks: action.payload};

    case UPDATE_CURRENT_TIME_PLAYING_TRACK:
      return { ...state, currentTimePlayingTrack: action.time};

    default:
    return state;
  }
}
