import React, { Component } from 'react';
import TracksFilter from './TracksFilter.js';
import TracksTable from './TracksTable.js';
import Loader from '../../Loader/Loader.js';
import * as utils from '../../../utils/tracks';
import {connect} from "react-redux";
import * as ReactDOM from "react-dom";

class Tracks extends Component {

  constructor(props, context) {
    super(props, context);
    this.loadMoreTracks = this.loadMoreTracks.bind(this);
    this.updateWidth = this.updateWidth.bind(this);
    this.updateTrackPadding = this.updateTrackPadding.bind(this);
    this.state = {
      tacksContainerWidth: 0,
      tacksContainerTopPadding: 0
    };
  }

  updateTrackPadding(prevPadding) {
    if (this.filterContainer) {
      let tacksContainerTopPadding = 0;
      const node = ReactDOM.findDOMNode(this.filterContainer);
      const tPadding = window.getComputedStyle(node).getPropertyValue('padding-top');
      const bPadding = window.getComputedStyle(node).getPropertyValue('padding-bottom');
      tacksContainerTopPadding = node.getBoundingClientRect().height - parseFloat(tPadding) - parseFloat(bPadding);
      if (prevPadding !== tacksContainerTopPadding) {
        this.setState({tacksContainerTopPadding});
      }
    }
  }

  updateWidth() {
    let tacksContainerWidth = 0;

    if (this.tracksContainer) {
      const node = ReactDOM.findDOMNode(this.tracksContainer);
      const rMargin = window.getComputedStyle(node).getPropertyValue('margin-right');
      const lMargin = window.getComputedStyle(node).getPropertyValue('margin-left');
      tacksContainerWidth = node.getBoundingClientRect().width + parseFloat(rMargin) + parseFloat(lMargin);
    }

    this.setState({tacksContainerWidth}, () => {
      this.updateTrackPadding();
    });
  }

  componentDidMount() {
    this.updateWidth();
    window.addEventListener("resize", this.updateWidth);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWidth);
  }

  componentDidUpdate(prevProps, prevState) {
    this.updateTrackPadding(prevState.tacksContainerTopPadding);
  }

  componentWillMount() {
    this.props.fetchTracks(this.props.peaks);
  }

  loadMoreTracks() {
    const {peaks, sortTracksBy, tracksSortDirection, tracks, toggleFetchTracksLoader} = this.props;
    toggleFetchTracksLoader(true);
    this.props.fetchTracks(peaks,
      sortTracksBy,
      tracksSortDirection,
      tracks);
  }

  render() {
    let {tracks,
      publishTrack,
      unPublishTrack,
      updateTrackStart,
      startPlayingTrack,
      playingWavesurfer,
      playingTrack,
      editingTrack,
      isPlayingTrackActive,
      stopPlayingTrack,
      playingTrackSource,
      updateCurrentTimeForPlayingTrack,
      fetchTrackPeaks,
      peaks,
      toggleLoader,
      allTracksLoaded,
      tracksLoading} = this.props;

    const {tacksContainerTopPadding, tacksContainerWidth} = this.state;
    return (
      <span>
        <div className="fixed-tracks-filter" style={{width: tacksContainerWidth}} ref={(ref) => this.filterContainer = ref}>
          <div className="catalogue">
          </div>


          <TracksFilter {...this.props} />
        </div>
        <div className="tracks-wrapper" style={{paddingTop: tacksContainerTopPadding}}>
          { <TracksTable tracks={tracks}
                         startPlayingTrack={startPlayingTrack}
                         stopPlayingTrack={stopPlayingTrack}
                         publishTrack={publishTrack}
                         unPublishTrack={unPublishTrack}
                         updateTrackStart={updateTrackStart}
                         playingWavesurfer={playingWavesurfer}
                         playingTrack={playingTrack}
                         editingTrack={editingTrack}
                         isPlayingTrackActive={isPlayingTrackActive}
                         playingTrackSource={playingTrackSource}
                         updateCurrentTimeForPlayingTrack={updateCurrentTimeForPlayingTrack}
                         fetchTrackPeaks={fetchTrackPeaks}
                         peaks={peaks}
                         toggleLoader={toggleLoader}
                         ref={(ref) => this.tracksContainer = ref}
            />
          }
          {tracksLoading && <div className="text-center"><Loader /></div>}
          { !allTracksLoaded && !tracksLoading &&
            <div className="load-more-tracks-button">
              <button className="button button_color_blue button_size_big button_weight_normal account__button"
              type="submit" onClick={this.loadMoreTracks}>Загрузить еще</button>

            </div>
          }


        </div>

      </span>
    )
  }
}

export default connect(state => ({  tracks: state.catalog.tracks,
  playingWavesurfer: state.catalog.playingWavesurfer,
  playingTrack: state.catalog.playingTrack,
  editingTrack: state.catalog.editingTrack,
  isPlayingTrackActive: state.catalog.isPlayingTrackActive,
  playingTrackSource: state.catalog.playingTrackSource}))(Tracks);
