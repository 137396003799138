import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Aside from './components/Layout/Aside';
import MainHeader from './components/Main/Header';
import LeftSidebar from './components/Main/LeftSidebar.js';
import AccountMenuItem from './components/Main/AccountMenuItem.js';
import User from './helpers/User';


export const PrivateRoute = ({components: Components, ...rest}) => {
  return (
    <Route {...rest} render={matchProps => (
      User.isLoggedin() ?
      <div className="layout">
        <Aside>
          <LeftSidebar {...matchProps} />
        </Aside>
        <div className="layout__center">
          <MainHeader {...matchProps} />
          <section>
            {
              Components.pageHeader &&
              <Components.pageHeader {...matchProps} />
            }

            <main className="layout__content" role="main">
              <Components.main {...matchProps} />
            </main>
          </section>
        </div>
        <Aside klass="layout__right-side" innerKlass="layout__right-block">
          <div className="layout__right-block js-sidebar">

            <AccountMenuItem {...matchProps} />
            {
              Components.right &&
              <Components.right {...matchProps} />
            }
          </div>
        </Aside>
      </div>
      : <Redirect to={{ pathname: '/login', state: { from: matchProps.location } }} />
    )} />
  )
};
