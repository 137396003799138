import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import userReducer from '../reducers/reducer_user';
import catalogReducer from '../reducers/reducer_catalog';
import { reducer as formReducer } from 'redux-form';
import {LOGOUT_USER} from "../actions/users";

export default function configureStore(history, initialState) {
  const reducers = {
    user: userReducer,
    form: formReducer,
    catalog: catalogReducer
  };

  const middleware = [
    thunk,
    routerMiddleware(history)
  ];

  // In development, use the browser's Redux dev tools extension if installed
  const enhancers = [];
  const isDevelopment = process.env.NODE_ENV === 'development';
  if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
    enhancers.push(window.devToolsExtension());
  }

  const appReducer = combineReducers({
    ...reducers,
    routing: routerReducer
  });

  const rootReducer = ( state, action ) => {
    if ( action.type === LOGOUT_USER ) {
      state = undefined;
    }

    return appReducer(state, action)
  };

  return createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers)
  );
}
