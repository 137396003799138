import React, { Component } from 'react';
import PropTypes from "prop-types";
import { reduxForm, Field } from 'redux-form';
import {renderField} from './renderField';
import { signInUser, signInUserSuccess, signInUserFailure } from '../actions/users';
import {api} from '../index';
import UserHelpers from "../helpers/User";
import connect from "react-redux/es/connect/connect";
import {bindActionCreators} from "redux";
import {
  clearPlayingTrack
} from "../actions/catalog";
import NProgress from "nprogress";
import UrlHelpers from "../../../OwnerApp/src/helpers/Url";


function validate(values) {
  var errors = {};
  var hasErrors = false;
  if (!values.username || values.username.trim() === '') {
    errors.username = 'Enter username';
    hasErrors = true;
  }
  if (!values.password || values.password.trim() === '') {
    errors.password = 'Enter password';
    hasErrors = true;
  }
  return hasErrors && errors;
}

const validateAndSignInUser = (values, dispatch) => {
  return dispatch(signInUser(values)).then((result) => {
        if (result.body.role !== 'Owner') {
          return dispatch(signInUserFailure({
              response: {
              body: {
                errors: [ {errorMessage: "Access restricted!"}]
              }
            }
          }));
        }
        const Mubicon = require('@mubicon/api');
        const oAuth = new Mubicon.OAuth();
        oAuth.accessToken = result.body.authToken;
        api.setDefaultAuthentication(oAuth);

        UserHelpers.setAccessToken(result.body.authToken, result.body.expiresIn);
        return dispatch(signInUserSuccess(result.body));
      }).catch((error) => {
          return dispatch(signInUserFailure(error));
      });
};


class SignInForm extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  componentWillMount() {
    UrlHelpers.siteRedirect(this.props.history);

    NProgress.done();
    this.props.clearPlayingTrack(this.props.playingWavesurfer);
  }

  componentWillReceiveProps(nextProps) {
    let token = UserHelpers.getAccessToken();
    let authenticated = token && token !== '';

    if (authenticated && !nextProps.user.error) {
      this.context.router.history.push('/');
    }
  }

  render() {
    const {handleSubmit, submitting, user} = this.props;
    return (
      <div className="container container__sing-in">
        <h1 className="account__heading heading-main">Вход</h1>
        <form className="signInForm" onSubmit={ handleSubmit(validateAndSignInUser) }>
          { user.error &&
            <div className="inputbox">
              <div className="inputbox__left">
                <p className="text-small">{user.error.login_failure}</p>
              </div>
            </div>
          }
          <Field
                 name="username"
                 type="text"
                 component={ renderField }
                 label="Логин"
                 placeholder="Введите Ваш Логин"
                 error={user.error && user.error.Login} />
          { user.error &&
            <div className="inputbox">
              <div className="inputbox__left">
                <p className="text-small">{user.error.Login}</p>
              </div>
            </div>
          }
          <Field
                 name="password"
                 type="password"
                 component={ renderField }
                 placeholder="*********"
                 label="Пароль" />
          { user.error &&
            <div className="inputbox">
              <div className="inputbox__left">
                <p className="text-small">{user.error.Password}</p>
              </div>
            </div>
          }
          <div className="inputbox inputbox_header">
            <div className="inputbox__left">
                <p className="text-small">
                    <b></b>
                </p>
            </div>
            <div className="inputbox__right">
              <button disabled={ submitting } className="button button_color_white button_size_big button_weight_normal button_radius_small account__button" type="submit">ВОЙТИ</button>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  clearPlayingTrack: bindActionCreators(clearPlayingTrack, dispatch)
});

export default connect(state => ({ playingWavesurfer: state.catalog.playingWavesurfer }), mapDispatchToProps)(reduxForm({
  form: 'SignInForm',
  validate
})(SignInForm));

