import React from 'react';
import { connect } from 'react-redux';
import {DragDropContext} from "react-dnd";
import ReactDnDHTML5Backend from "react-dnd-html5-backend";
import UserHelpers from "../helpers/User";
import AlertTemplate from './AlertTemplate';

import Alert from 'react-s-alert';

const mapDispatchToProps = (dispatch) => {
  return {
   isLoggedin: () =>{
     const token = UserHelpers.getAccessToken();
     return token && token !== '';
   }
  };
};

function mapStateToProps(state, ownProps) {
  return {
    user: state.user.user,
    playingTrack: state.catalog.playingTrack,
    playingWavesurfer: state.catalog.playingWavesurfer,
  };
}

class Layout extends React.Component {

  render() {
    return (
      <span>
        <span>
          {this.props.children}
        </span>
        <Alert stack={{limit: 3}} contentTemplate={AlertTemplate} position='top-right'  effect='stackslide' />
      </span>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DragDropContext(ReactDnDHTML5Backend)(Layout));
