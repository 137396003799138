import React, { Component } from 'react';
import { reduxForm, Field, SubmissionError } from 'redux-form';
import {renderField} from '../renderField';
import {updatePassword, updatePasswordSuccess, updatePasswordFailure} from '../../actions/users';
import UserHelpers from "../../helpers/User";


//TODO:: fix validation
function validate(values) {
  let errors = {};
  let hasErrors = false;
  if (!values.email || values.email.trim() === '') {
    errors.email = 'Enter email';
    hasErrors = true;
  }
  return hasErrors && errors;
}

class UpdatePasswordForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      success: false
    };

    this.validateAndUpdatePassword = this.validateAndUpdatePassword.bind(this);
  }

  componentWillUnmount() {
    this.setState({success: false});
  }

  validateAndUpdatePassword(values, dispatch) {
    return dispatch(updatePassword(values)).then((result) => {
      dispatch(updatePasswordSuccess(result.body));
      this.setState({success: true});
    }).catch((error) => {
      dispatch(updatePasswordFailure(error.response));
    });
  };

  render() {
    const {handleSubmit} = this.props;
    let token = UserHelpers.fetchUserInfoFromToken();
    const email = token && token !== '' ?  token.email : '';
    return (
        <form className="account__form" method="post" onSubmit={ handleSubmit(this.validateAndUpdatePassword) }>
          {this.state.success &&
          <h1 className="heading-main">Ваш пароль успешно изменен, ожидайте письмо с новым паролем на E-mail</h1>
          }
          {!this.state.success &&
          <div>
            <div className="inputbox">
              <div className="inputbox__left">
                <p className="text-small">
                  E-mail
                </p>
              </div>
              <div className="inputbox__right">
                <input disabled name="email" className="input" type="text" value={email}/>
              </div>
            </div>

            <div className="inputbox inputbox_header">
              <div className="inputbox__left">
                <p className="text-small">
                  <b/>
                </p>
              </div>
              <div className="inputbox__right">
                <button
                  className="button button_color_white button_size_big button_weight_normal button_radius_small account__button float-right"
                  type="submit">СБРОСИТЬ
                </button>
              </div>
            </div>
          </div>
          }
        </form>
    );
  }
}

export default reduxForm({
  form: 'UpdatePasswordForm',
  validate
})(UpdatePasswordForm)
