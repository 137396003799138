import React, { Component } from 'react';
import TracksTableItemWithHeaderPlayer from '../Player/TracksTableItemWithHeaderPlayer.js';
import TracksTableHeader from "./TracksTableHeader";

class TracksTable extends Component {


  render() {

    let {tracks,
      addTrackToPlaylist,
      removeTrackFromPlaylist,
      playlist,
      startPlayingTrack,
      playingWavesurfer,
      playingTrack,
      editingTrack,
      isPlayingTrackActive,
      stopPlayingTrack,
      playingTrackSource,
      updateCurrentTimeForPlayingTrack,
      peaks,
      publishTrack,
      unPublishTrack,
      updateTrackStart,
      fetchTrackPeaks,
      toggleLoader} = this.props;

    return (
      <div className="track-table catalogue__table">
        <TracksTableHeader />
        {tracks.map((track, i) =>
          <TracksTableItemWithHeaderPlayer key={track.trackId}
                           track={track}
                           addTrackToPlaylist={addTrackToPlaylist}
                           removeTrackFromPlaylist={removeTrackFromPlaylist}
                           playlist={playlist}
                           publishTrack={publishTrack}
                           unPublishTrack={unPublishTrack}
                           updateTrackStart={updateTrackStart}
                           startPlayingTrack={startPlayingTrack}
                           stopPlayingTrack={stopPlayingTrack}
                           playingWavesurfer={playingWavesurfer}
                           playingTrack={playingTrack}
                           editingTrack={editingTrack}
                           isPlayingTrackActive={isPlayingTrackActive}
                           playingTrackSource={playingTrackSource}
                           updateCurrentTimeForPlayingTrack={updateCurrentTimeForPlayingTrack}
                           peaks={peaks}
                           fetchTrackPeaks={fetchTrackPeaks}
                           toggleLoader={toggleLoader}
          />
        )}

      </div>
    )
  }
}

export default TracksTable;
