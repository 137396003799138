import React from 'react';
import { Link } from 'react-router-dom';
import User from '../../helpers/User';
import connect from "react-redux/es/connect/connect";

import {ReactComponent as AccountIcon} from '../../icons/account_circle.svg';

class AccountMenuItem extends React.Component
{

  render() {
    return (
      <div className="right-header">
          { User.isLoggedin() &&
            <ul className="icon-menu right-header__menu">
                <li className="icon-menu__item">
                    <Link to="/account" className={"icon-menu__link "}>
                        <AccountIcon className="icon-menu__icon svg-icon" />
                        <span className="icon-menu__text">Аккаунт</span>
                    </Link>
                </li>
            </ul>
          }
      </div>
    );
  }
}

export default connect(state => ({}), null)(AccountMenuItem);
