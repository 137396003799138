import React, { Component } from 'react';
import StarsRating from '../../StarsRating.js';
import * as utils from "../../../utils/tracks";
import DragSource from "react-dnd/lib/DragSource";
import {renderGenres} from "../../../utils/tracks";

import {ReactComponent as CloseIcon} from '../../../icons/close.svg';
import {ReactComponent as PauseIcon} from '../../../icons/pause_circle_outline.svg';
import {ReactComponent as PlayIcon} from '../../../icons/play_arrow.svg';
import {ReactComponent as PowerSettingsIcon} from '../../../icons/power_settings_new.svg';
import {ReactComponent as EditBlackIcon} from '../../../icons/edit-black.svg';
import UserHelpers from "../../../helpers/User";

class TracksTableItem extends Component {

  constructor(props, context) {
    super(props, context);
    this.playClickHandler = this.playClickHandler.bind(this);
    this.openEditorClickHandler = this.openEditorClickHandler.bind(this);
    this.enableRequestClickHandler = this.enableRequestClickHandler.bind(this);
    this.disableRequestClickHandler = this.disableRequestClickHandler.bind(this);
    this.state = {
      isEditorOpen: false
    }
  }

  enableRequestClickHandler(e) {
    e.preventDefault();
    let {track} = this.props;
    this.props.publishTrack(track.fileHash);
  }

  disableRequestClickHandler(e) {
    e.preventDefault();
    let {track} = this.props;
    this.props.unPublishTrack(track.fileHash);
  }

  openEditorClickHandler(e) {
    e.preventDefault();
    let {track} = this.props;

    if (track.publication) {
      UserHelpers.showError("Published tracks is denied from change");
      return;
    }

    this.props.updateTrackStart(track);
  }

  playClickHandler(e) {
    let {play, track, peaks} = this.props;
    play(track, e.target, peaks[track.trackId]);
  }

  render() {
    const {pause,
      track,
      editingTrack,
      isContentTrackActive,
      isTrackActive,
      isTrackSelected,
      connectDragSource
    } = this.props;
    const {title, artist, genreTracks, duration, rating, publication, trackId} = this.props.track;
    const isEditorOpen = editingTrack && trackId === editingTrack.trackId;
    return connectDragSource (
      <div className={"track-table-item " +
                        (isContentTrackActive(track) ?
                        'track-table-item_active' : '')}>
        <div>
          <span className="track-table-item__text track-table-item__text_title">{title}</span>
          <span className="track-table-item__text track-table-item__text_artist">{artist}</span>
          <span className="track-table-item__text track-table-item__text_genre">
            {renderGenres(genreTracks)}
          </span>
          <span
            className="track-table-item__text track-table-item__text_time">{utils.renderTrackDuration(duration)}</span>

          <StarsRating rating={rating} className="track-table-item__rating"/>
          <div className="track-table-item__controls">
            {isContentTrackActive(track) &&
            <PauseIcon className="track-table-item__control-icon
                              track-table-item__control-icon_active
                              svg-icon"
                       onClick={pause}/>
            }
            {!isContentTrackActive(track) &&
            <PlayIcon className="track-table-item__control-icon
                  track-table-item__control-icon_play
                  track-table-item__control-icon_active
                  svg-icon"
                      onClick={this.playClickHandler}/>
            }
          </div>
          {publication &&
          <div className="track-table-item__enable"
               onClick={this.disableRequestClickHandler}>
            <PowerSettingsIcon className="track-table-item__enable-icon track-table-item__enable-icon_active svg-icon" />
          </div>
          }
          {!publication &&
          <div className="track-table-item__enable"
               onClick={this.enableRequestClickHandler}>
            <PowerSettingsIcon className="track-table-item__enable-icon svg-icon" />
          </div>
          }
          {isEditorOpen &&
          <div className="track-table-item__edit">
            <EditBlackIcon className="track-table-item__edit-icon track-table-item__edit-icon_active svg-icon" />
          </div>
          }
          {!isEditorOpen &&
          <div className="track-table-item__edit"
               onClick={this.openEditorClickHandler}>
            <EditBlackIcon className="track-table-item__edit-icon svg-icon" />
          </div>
          }
        </div>
        {isEditorOpen &&
        <div className="track-table-item__editor">
          test
        </div>
        }
      </div>
    )
  }
}

export default DragSource('playlist-track', utils.trackDragSource, utils.getCollectTrack)(TracksTableItem);


