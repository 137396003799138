import React from 'react';
import {connect} from 'react-redux';
import { logoutUser } from '../actions/users';
import UserHelpers from "../helpers/User";
import UrlHelpers from "../helpers/Url";

let Logout;

Logout = class extends React.Component {
    componentWillMount () {
      UserHelpers.removeAccessToken();
      this.props.dispatch(logoutUser());
      UrlHelpers.siteRedirect(this.props.history);
    }

    render () {
        return null;
    }
};

export default connect()(Logout);
