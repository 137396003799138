const MONTHS = {
  ru: [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь'
  ]
};

export default class StatisticHelpers {
  static get CurrentDate() {
    return new Date();
  }

  static get DefaultStart() {
    return new Date(StatisticHelpers.CurrentDate.getFullYear(), StatisticHelpers.CurrentDate.getMonth(), 1);
  }

  static get DefaultEnd() {
    return new Date(StatisticHelpers.CurrentDate.getFullYear(), StatisticHelpers.CurrentDate.getMonth() + 1, 0);
  }

  static RenderDate(date) {
    return `${date.getDate()} ${MONTHS['ru'][date.getMonth()]}, ${date.getFullYear()} года`;
  }
}
