import React, {Component} from 'react';

export default class TracksCheckModule extends Component {

  constructor(props, context) {
    super(props, context);
    this.checkTracks = this.checkTracks.bind(this);
    this.state = {
      done: false
    };
  }

  checkTracks() {
    this.props.checkTracks();
    this.setState({done: true});
    setTimeout(() => {
      this.setState({done: false});
    }, 5000)
  }

  render() {
    const {done} = this.state;
    return (
      <div className="calendar-controls calendar-container__result calendar-container__result_value">
        {!done &&
        <button className="button button_size_big button_color_white button_weight_normal account__button"
                type="submit"
                onClick={this.checkTracks}>
          ПРОВЕРИТЬ ТРЕКИ
        </button>
        }
        {done &&
        <button className="button button_size_big button_color_light-grey button_weight_normal account__button"
                type="submit"
                disabled={true}>
          ЗАПРОС ОТПРАВЛЕН
        </button>
        }
      </div>
    )
  }
}
