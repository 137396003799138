import DroppableInformationRightSidebar from '../components/Catalog/Information/InformationRightSidebar';
import { connect } from 'react-redux';
import {bindActionCreators} from "redux";
import {
  fetchStatistic,
  uploadTracks,
  uploadTracksStart,
  resetStatistic,
  fetchGenres,
  updateSidebarAccordionSlug,
  checkTracks
} from '../actions/catalog';

import {
  fetchOwner,
  createTicket,
  clearTicket
} from '../actions/users';

const mapDispatchToProps = (dispatch) => ({
  fetchOwner: bindActionCreators(fetchOwner, dispatch),
  fetchStatistic: bindActionCreators(fetchStatistic, dispatch),
  fetchGenres: bindActionCreators(fetchGenres, dispatch),
  uploadTracks: bindActionCreators(uploadTracks, dispatch),
  uploadTracksStart: bindActionCreators(uploadTracksStart, dispatch),
  resetStatistic: bindActionCreators(resetStatistic, dispatch),
  updateSidebarAccordionSlug: bindActionCreators(updateSidebarAccordionSlug, dispatch),
  checkTracks: bindActionCreators(checkTracks, dispatch),
  createTicket: bindActionCreators(createTicket, dispatch),
  clearTicket: bindActionCreators(clearTicket, dispatch)
});

function mapStateToProps(state) {
  return {
    ticket: state.user.ticket,
    user: state.user.user,
    owner: state.user.owner,
    statistic: state.catalog.statistic,
    genres: state.catalog.genres,
    statisticStart: state.catalog.statisticStart,
    statisticEnd: state.catalog.statisticEnd,
    uploadTracksActive: state.catalog.uploadTracksActive,
    uploadTracksResult: state.catalog.uploadTracksResult,
    editingTrack: state.catalog.editingTrack,
    sidebarSlug: state.catalog.sidebarSlug
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DroppableInformationRightSidebar);
