import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {AccordionItem} from './AccordionItem.js';

import {ReactComponent as MenuIcon} from '../../icons/menu.svg';

export default class Accordion extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
          activeItemIndex: props.activeItemIndex != null ? props.activeItemIndex : -1
        };
        this.handleAccordionItemClick = this.handleAccordionItemClick.bind(this);
    }

    handleAccordionItemClick(index) {
      let activeItemIndex = -1;
      if (this.state.activeItemIndex !== index) {
        activeItemIndex = index
      }
      this.setState({ activeItemIndex });
    }

    componentWillReceiveProps(nextProps) {
      if (nextProps.activeItemIndex !== null) {
        this.setState({
          activeItemIndex: nextProps.activeItemIndex
        })
      }
    }

    render() {
      const self = this;
      return (
        <div className="accordion">
          { this.props.header &&
            <div className="accordion__header">
              <MenuIcon className="accordion__icon svg-icon"/>
              <h2 className="accordion__heading">{this.props.header}</h2>
            </div>
          }
          <div className="accordion__items">
            {this.props.children
              .filter(child => child.props.enabled !== false)
              .map((child, i) => (
              <AccordionItem
                handleAccordionItemClick={self.handleAccordionItemClick}
                accordionItemTitle={child.props.title}
                activeItemIndex={self.state.activeItemIndex}
                index={i}
                key={i}
              >
                {child.props.children}
              </AccordionItem>
            ))}
          </div>
        </div>
      )
    }
}
Accordion.propTypes = {
  activeItemIndex: PropTypes.number
};
